@import 'flags';

@import './styles/colors.scss';
@import './styles/white-label';
@import './styles/variables.scss';

/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/variables-dark';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';

@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/utilities';
@import '../node_modules/bootstrap/scss/utilities/api';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/modal';

// ng-select
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';

@import './styles/tooltip.scss';
@import './styles/text-tooltip.scss';
@import './styles/modal.scss';
@import './styles/select-dropdown.scss';
@import './styles/base.scss';
@import './styles/responsive.scss';

@import '_quill_snow.scss';
