@import 'variables';
@import 'colors';

:root {
  --var-font-family: #{$font-family-sans-serif};
  --var-intro-background: #{$primary-dark-blue};
  --var-menu-bg: #{$primary-dark-blue};
  --var-intro-title: #{$white};
  --var-intro-text: #{$white};
  --var-menu-text: #{$white};
  --var-menu-icons: #{$white};
  --var-intro-question: #{$grey3};
  --var-intro-dropdown-placeholder: #{$grey5};
  --var-intro-dropdown-value: #{$white};
  --var-intro-dropdown-hover: #{$primary-low-opacity};
  --var-intro-dropdown-underline: #{$grey7};
  --var-intro-dropdown-underline-focused: #{$grey7};
  --var-intro-tooltip-hover: #{$grey7};
  --var-intro-tooltip-text: #{$white};
  --var-intro-contrast-color: #202634;
  --var-intro-link: #d2d3d6;
  --var-info-link: #{$primary};
  --var-steps-counter: #{$primary-dark-blue};
  --var-steps-header-divider: #{$white};
  --var-steps-header-title: #{$white};
  --var-steps-header: #{$primary-dark-blue};
  --var-steps-title: #{$primary-dark-blue};
  --var-steps-required: #{$danger};
  --var-steps-radio-stroke: #{$primary-dark-blue};
  --var-steps-radio-text: #{$primary-dark-blue};
  --var-steps-radio-icon: #{$primary-dark-blue};
  --var-steps-radio-selected-bg: #{$primary-dark-blue};
  --var-steps-radio-selected-border: #{$primary-dark-blue};
  --var-steps-radio-selected-text: #{$white};
  --var-steps-radio-selected-icon: #{$white};
  --var-steps-question-text: #{$primary-dark-blue};
  --var-steps-footer: #{$primary-dark-blue};
  --var-results-link: #{$primary};
  --var-results-eval-icon: #{$white};
  --var-results-eval-bg: #{$primary};
  --var-tooltip-hover: #{$primary-dark-blue};
  --var-tooltip-text: #{$white};
  --var-tooltip-icon-primary: #{$primary-dark-blue};
  --var-tooltip-icon-secondary: #{$white};
  --var-tooltip-icon-primary-variant: #{$primary-dark-blue};
  --var-tooltip-icon-secondary-variant: #{$white};
  --var-tooltip-icon-primary-dark: #{$primary-dark-blue};
  --var-tooltip-icon-secondary-dark: #{$white};
  --var-tooltip-link: #{$primary};
  --var-link: #{$primary};
  --var-primary-cta-bg: #{$primary};
  --var-primary-cta-text: #{$white};
  --var-primary-cta-disabled-bg: #{$grey3};
  --var-primary-cta-disabled-text: #{$white};
  --var-primary-cta-hover-bg: #2a92d1;
  --var-secondary-cta-stroke: #{$primary};
  --var-secondary-cta-text: #{$primary};
  --var-secondary-cta-disabled-stroke: #{$grey3};
  --var-secondary-cta-disabled-text: #{$grey3};
  --var-secondary-cta-hover-bg: #{$primary-low-opacity};
  --var-notification-bg: #{$primary};
  --var-notification-text: #{$white};
  --var-notification-icon: #{$white};
  --var-checkbox-unselected: #{$white-low-opacity};
  --var-checkbox-selected: #{$primary-light-blue};
  --var-checkbox-text: #{$white};
  --var-warning-bg: #{$danger};
  --var-warning-text: #{$white};
  --var-warning-icon: #{$white};
  --var-mobile-steps-primaryCTA-bg: #{$primary};
  --var-mobile-steps-primaryCTA-icon: #{$white};
  --var-mobile-steps-primaryCTA-text: #{$primary};
  --var-mobile-steps-secondaryCTA-stroke: #{$primary};
  --var-mobile-steps-secondaryCTA-icon: #{$primary};
  --var-mobile-steps-secondaryCTA-text: #{$primary};
  --var-mobile-steps-scenariosMenu-bg: #{$primary-dark-blue};
  --var-mobile-steps-scenariosMenu-text: #{$white};
  --var-mobile-steps-scenariosMenu-btn: #{$primary};
  --var-mobile-steps-scenariosMenu-btn-icon: #{$white};
  --var-mobile-steps-scenariosMenu-btn-disabled: #{$primary};
  --var-context-icon: #{$white};
  --var-context-border-color: #797c85;
  --var-context-border-color-80opacity: #797c85cc;
  --var-intro-search-border: #0000001f;
  --var-intro-context-border: #0000001f;
  --var-radio-selected: #{$primary};
  --var-radio-unselected: #{$white};
  --var-radio-dark-theme-contrast-1: #{$white};
  --var-radio-dark-theme-contrast-2: #{$primary-dark-blue};
  // portal
  --var-main-logo: #{$white};
  --var-main-header-bg: #{$primary-dark-blue};
  --var-main-welcome-text: #{$primary-dark-blue};
  --var-main-welcome-sub-text: #{$grey6};
  --var-main-tabs-text: #{$black};
  --var-main-tabs-selected: #{$color-portal-tab-selected};
  --var-main-tabs-line: rgba(0, 0, 0, 0.12);
  --var-main-app-title: #{$primary-dark-blue};
  --var-portal-label-text: #{$white};
  --var-portal-label-grey: #{$grey5};
  --var-portal-label-orange: #{$color-app-badge-cross-border};
  --var-portal-label-pink: #{$color-app-badge-aml};
  --var-portal-label-blue: #{$color-app-badge-dataprotection};
  --var-portal-label-blue-grey: #{$color-app-badge-tax};
  --var-main-app-title-inside: #{$white};
  --var-support-cta: #{$primary-dark-blue};
  --var-support-text: #{$white};
  --var-support-icon: #{$white};
  --var-main-app-img-lines-hover: #ffffffb3;
  --var-main-app-img-background-hover: #2b3446b3;
  --var-main-app-img-background: #{$primary-dark-blue};
  --var-main-app-img-lines: #{$white};
  --var-main-apps-color: #{$white};
}
