//-------- RESPONSIVE DESIGN -------//
$screen-phone-max: 767px;

$screen-tablet-min: 768px;
$screen-tablet-max: 991px;

$screen-desktop-min: 992px;

$screen-phone-header-height: 45px;

@mixin screen-phone {
  @media only screen and (max-width: $screen-phone-max) {
    @content;
  }
}

@mixin screen-tablet {
  @media only screen and (min-width: $screen-tablet-min) and (max-width: $screen-tablet-max),
    only screen and (min-width: $screen-tablet-min) and (orientation: portrait) {
    @content;
  }
}

@mixin screen-desktop {
  @media only screen and (min-width: $screen-desktop-min) {
    @content;
  }
}

@mixin webkit-support {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    @content;
  }
}

/* HOW TO USE

@import 'responsive.scss';

@include screen-phone {
  // Your CSS here
}
*/
