@import 'colors';
@import '../../node_modules/bootstrap/scss/variables';

// Styling done at this level since the dropdown needs to be appended to the body element
ng-dropdown-panel,
.ng-dropdown-panel {
  z-index: calc($zindex-modal + 10) !important;
}
ng-dropdown-panel {
  z-index: calc($zindex-modal + 10);
  .option-icon.flag {
    margin-right: 10px;
    transform: scale(0.8);
  }

  .option-label {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    color: $grey6;
  }

  .scroll-host {
    position: initial !important;
  }

  .ng-dropdown-panel-items {
    margin: 10px 0;
  }

  .ng-option {
    padding: 0 15px !important;
  }

  .ng-option {
    color: $primary-dark-blue !important;
    background-color: $white !important;

    &.ng-option-marked {
      background-color: $grey2 !important;
    }

    &:hover {
      background-color: $grey2 !important;
    }

    &.ng-option-selected {
      background-color: $grey3 !important;
    }
  }

  &.ng-select-multiple .ng-option-selected {
    background-color: transparent !important;
    &.ng-option-marked {
      background-color: $grey2 !important;
    }
  }
}
