@import 'responsive.scss';
// ng-bootstrap modal customization

ngb-modal-backdrop {
  display: none;
}

.modal-open .modal {
  background-color: rgba(250, 250, 250, 0.9);

  .modal-dialog {
    max-width: 660px;
  }

  .modal-content {
    box-shadow: rgb(0 0 0 / 30%) 0 0 24px 0;
    border: 0;
    max-height: 100vh;
    overflow: auto;
  }
}

.modal-open .modal.territorial-scope-modal .modal-dialog,
.modal-open .modal.role-modal .modal-dialog,
.modal-open .modal.export-modal .modal-dialog,
.modal-open .modal.password-modal .modal-dialog,
.modal-open .modal.large-modal .modal-dialog,
.modal-open .modal.change-context-modal .modal-dialog,
.modal-open .modal.mm-language-picker-modal .modal-dialog {
  max-width: 620px;
}

.modal-open .modal.tooltip-modal .modal-dialog {
  max-width: 600px;
}

.modal-open .modal.result-info-modal .modal-dialog {
  max-width: 580px;
}

.modal-open .modal.portfolio-modal .modal-dialog {
  max-width: 500px;
}

.modal-open .modal.mm-copy-modal .modal-dialog {
  max-width: 660px;

  .modal-dialog {
    min-height: 460px;
  }
}

.modal-open .mm-edit-scenario-modal .modal-dialog {
  max-width: 660px;
  height: 100vh;

  .modal-content {
    max-height: 90%;
  }
}

@include screen-phone() {
  .modal-dialog-centered {
    padding-bottom: 0px;

    .modal-content {
      margin: 0 10px;
    }
  }
}
