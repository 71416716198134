*[tooltip]:hover:before {
  content: attr(tooltip);
  position: absolute;
  max-width: 350px;
  padding: 4px;
  border-radius: 2px;
  color: var(--var-tooltip-text);
  background-color: var(--var-intro-tooltip-hover);
  font-size: 12px;
  line-height: 16px;
  transform: translateY(-100%);
  text-transform: none;
  overflow-wrap: normal;
  overflow: visible;
  white-space: normal;
  font-weight: 400;
}

*.tooltip-blue[tooltip]:hover:before {
  background-color: var(--var-tooltip-hover);
}
