@import 'colors';

$raleway: 'Raleway';

$font-family-sans-serif: $raleway, sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875; // 14px
$font-size-panel: $font-size-base * 0.8125; //13px
$font-size-xs: $font-size-base * 0.75; // 12px
$font-size-xxs: $font-size-base * 0.625; // 10px
$font-size-pill: $font-size-base * 0.5625; //9px

$h1-font-size: $font-size-base * 1.75; // 28px
$h2-font-size: $font-size-base * 1.625; //26px
$h3-font-size: $font-size-base * 1.5; //24px
$h4-font-size: $font-size-base * 1.375; //22px
$h5-font-size: $font-size-base * 1.125; //18px
$h6-font-size: $font-size-base; //16px

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

//----- BODY -----//
//
$body-bg: $white;
$body-color: $primary-dark-blue;

//----- GRID -----//
//
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.5
    ),
    2: (
      $spacer * 1
    ),
    3: $spacer * 1.5,
    4: (
      $spacer * 2
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1560px
);

$container-max-widths: (
  sm: 575px,
  md: 767px,
  lg: 1291px,
  xl: 1659px
);

$grid-gutter-width: 1rem;

$breakpoint-mobile: 768px;
$mobile-padding: 90px;

$apps-screen-width: 1024px;

$mat-input-padding-bottom: 1.25rem;

$standard-font-size: 12px;

$header-height: 56px;
$test-mode-banner-height: 50px;

$link-decoration: none;
