@import 'responsive.scss';
/*
--------------------------------------------------------------------------------
HTML elements look n feel
--------------------------------------------------------------------------------
*/

html {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  height: 100%;
}

body {
  font-family: var(--var-font-family);
  font-style: normal;
  font-weight: $font-weight-normal;
  letter-spacing: -0.005em;
  line-height: $line-height-base;
  font-size: $font-size-base;
  min-height: 100vh;
  @include webkit-support {
    min-height: -webkit-fill-available;
  }
  width: 100%;
  height: 100%;
}

h1 {
  font-weight: $font-weight-semibold;
  font-size: $h1-font-size;
  line-height: 1.3;
}

h2 {
  font-weight: $font-weight-light;
  font-size: $h2-font-size;
  line-height: 1.2;
}

h3 {
  font-weight: $font-weight-bold;
  font-size: $h3-font-size;
  line-height: 1.2;
}

h4 {
  font-weight: $font-weight-light;
  font-size: $h4-font-size;
  line-height: 1.2;
}

h5 {
  font-weight: $font-weight-semibold;
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

label {
  font-size: $font-size-base;
}
