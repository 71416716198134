$black: #000000;
$white: #ffffff;
$white2: #feffff;
$white3: #f5f7ef;
$white-low-opacity: rgba(255, 255, 255, 0.54);

$primary: #48a3da;
$primary-low-opacity: rgba(72, 163, 218, 0.15);
$primary-dark-blue: #2b3446;
$primary-light-blue: #00a8f1;

$grey1: #fafafa;
$grey2: #f4f4f4;
$grey3: #e0e0e0;
$grey4: #c4c4c4;
$grey5: #9e9e9e;
$grey6: #595959;
$grey7: #4a4a4b;
$grey8: #262626;

$footer-text-color: #49586a;
$white-label-footer: #262626;

$danger: #f15632;
$disabled: $grey5;

$red-notif: #fe461b;
$orange-notif: #ffb405;
$green-notif: #4dd57f;

$orange-results: #f6b383;
$green-results: #9cc787;
$red-results: #ff7373;
$color-background-odd: #fafafa;
$context-saved: #f39654;

$mandatory-color: rgba(255, 55, 55, 0.95);

// portal, review
$color-app-badge-tax: #3c4959;
$color-app-badge-cross-border: #f15632;
$color-app-badge-aml: #f42654;
$color-app-badge-dataprotection: #509bca;
$color-portal-tab-selected: #e96245;
$logout-color: #ff461a;
$logout-color-hover: rgba(255, 70, 26, 0.08);
